// Map.tsx
import React, { useEffect, useState } from 'react';
import { Loader } from '@googlemaps/js-api-loader';
import MapTypes from '../types/MapTypes';

const Map: React.FC<MapTypes & { id: string }> = ({ address, id }) => {
  const [location, setLocation] = useState({ latitude: 0, longitude: 0 });
  useEffect(() => {
    getCoordinatesByAddress(address)
    const loader = new Loader({
      apiKey: process.env.REACT_APP_API_KEY_MAPS || "", 
      version: "weekly",
    });

    async function getCoordinatesByAddress(address: string): Promise<any> {
      const url = `https://nominatim.openstreetmap.org/search?q=${encodeURIComponent(address)}&format=json&addressdetails=1`;
      try {
        const response = await fetch(url);
        const data = await response.json();

        if (data && data.length > 0) {
          setLocation({
            latitude: parseFloat(data[0].lat),
            longitude: parseFloat(data[0].lon),
          });
        } else {
          throw new Error("Endereço não encontrado");
        }
      } catch (error) {
        console.error("Erro ao obter coordenadas:", error);
      }
    }

    loader.load().then((google) => {
      const map = new google.maps.Map(document.getElementById(id) as HTMLElement, {
        center: { lat: location.latitude, lng: location.longitude },
        zoom: 15,
      });

      new google.maps.Marker({
        position: { lat: location.latitude, lng: location.longitude },
        map: map,
        title: "Localização Encontrada",
      });
    }).catch(error => {
      console.error("Erro ao carregar o Google Maps", error);
    });
  }, [location.latitude, location.longitude, id]);

  return <div id={id} style={{ height: '200px', width: '100%', borderRadius: '10px', overflow: 'hidden' }} />;
};

export default Map;
