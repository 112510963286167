import React, { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { ToastContainer,toast } from 'react-toastify'
import { io } from 'socket.io-client';
import { useAuth } from '../contexts/AuthProvider';
import NewItemModel from '../models/NewItemModel';
import ItemAddedModel from '../models/ItemAddedModel';
import Header from './../components/Header';
import Footer from './../components/Footer';
import Map from '../components/Map';
import Slider from 'react-slick';
import Itens from '../models/Itens';
import '../styles/Home.css';
import 'react-toastify/dist/ReactToastify.css';
import Cookies from 'js-cookie';
const socket = io(process.env.REACT_APP_PAYMENT_SERVICE_URL)

const Home: React.FC = () => {
  const [itensSelecionados, setItensSelecionados] = useState<string[]>([]);
  const [isFormVisible, setIsFormVisible] = useState<boolean>(false);
  const [itensAdicionados, setItensAdicionados] = useState<ItemAddedModel[]>([]);
  const [novoItem, setNovoItem] = useState<NewItemModel>({});
  const [address, setAddress] = useState<string | null>(null);
  const [isLoading, setIsLoading] = useState(false)
  const [filteredJobs, setFilteredJobs] = useState<any[]>([]);
  const [jobStatus, setJobStatus] = useState<{ [key: string]: string }>({});
  const { user, isAuthenticated, loading } = useAuth();
  const jobServiceUrl = process.env.REACT_APP_JOB_SERVICE_URL || 'http://localhost:3002';
  const userServiceUrl = process.env.REACT_APP_USER_SERVICE_URL || 'http://localhost:3001';
  const token = Cookies.get('access_token');
  const navigate = useNavigate();
  const email = String(user?.email);
  const type = user?.type;

  useEffect(() => {
    getAddress();
    if (!loading && !isAuthenticated) {
      navigate('/');
    } else if (isAuthenticated && String(type) == '2') {
      getJobs();
      socket.connect();
      socket.on('connect', () => {
        console.log('Connected to payment service');
      });
      socket.on('paymentConfirmed',()=>{
        getJobs();
      })
      return () => {
        socket.off('connect');
        socket.disconnect();
      };
    }
  }, [loading, isAuthenticated, navigate, email, type]);
  
  const itens = Itens;

  const settings = {
    dots: true,
    infinite: true,
    speed: 500,
    slidesToShow: 3,
    slidesToScroll: 1,
    responsive: [
      { breakpoint: 768, settings: { slidesToShow: 2 } },
      { breakpoint: 480, settings: { slidesToShow: 1 } },
    ],
  };

  const selecionarItem = (item: string) => {
    if (itensSelecionados.includes(item)) {
      setItensSelecionados(itensSelecionados.filter(i => i !== item));
    } else {
      setItensSelecionados([...itensSelecionados, item]);
    }
    setIsFormVisible(true);
  };

  const handleChange = (e: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) => {
    const { name, value } = e.target as HTMLInputElement;
    const files = (e.target as HTMLInputElement).files;
    setNovoItem(prevState => ({
      ...prevState,
      [name]: files ? files[0] : value,
    }));
  };

  const adicionarItem = async (): Promise<void> => {
    setIsLoading(true);
    const valor = calcularValor();
    const itensParaAdicionar = itensSelecionados.map(item => ({
      name: item,
      ...novoItem,
    }));

    try {
      await createJob(novoItem, valor);
      setItensAdicionados([...itensAdicionados, ...itensParaAdicionar]);
      setItensSelecionados([]);
      setNovoItem({});
      setIsFormVisible(false);
    } catch (error) {
      toast.error('Erro ao adicionar o item.');
    } finally {
      setIsLoading(false); 
    }
  };
  
  const formatAddress = (addr: string): string => {
    // Criar a string do endereço
    const addressComponents = addr.split(',') // Dividir a string em componentes
      .filter(component => component && typeof component === 'string') // Filtrar valores válidos
      .map(component => component.trim()) // Remover espaços em branco
      .filter(Boolean); // Remover componentes vazios
  
    // Pegar os primeiros 5 componentes e unir com vírgulas
    const firstFiveComponents = addressComponents.slice(0, 6).join(', ');
  
    // Verificar se algum componente foi adicionado e retornar a string formatada
    if (firstFiveComponents) {
      return firstFiveComponents;
    }
  
    return 'Endereço não definido'; // Retornar mensagem padrão se não houver componentes
  };

  async function getJobs(): Promise<[]> {
    try {
      const url = `${jobServiceUrl}/job`;
      const response = await fetch(url, {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      });
      const data = await response.json();
      
      const jobsWithPaymentDone = data.filter((job: any) => job.pagamento === "feito");
      
      setFilteredJobs(jobsWithPaymentDone);
      const initialStatuses = jobsWithPaymentDone.reduce((acc: { [x: string]: any; }, job: { id: string | number; status: any; }) => {
        acc[job.id] = job.status;
        return acc;
      }, {});
      setJobStatus(initialStatuses);
      return data;
    } catch (error) {
      console.error("Erro ao carregar serviços:", error);
      return [];
    }
  };

  async function getAddress(): Promise<String | null> {
    try {
      const token = Cookies.get('access_token');
      const response = await fetch(`${userServiceUrl}/usuario/email/${email}`, {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      });
  
      if (!response.ok) {
        throw new Error(`HTTP error! Status: ${response.status}`);
      }
  
      const text = await response.text();
  
      if (!text) {
        return null;
      }
  
      const data = JSON.parse(text);
      const { address } = data;
  
      setAddress(address);
      return address;
    } catch (error) {
      return null;
    }
  };
  
  async function createJob(item: any, valor: number): Promise<void> {
      const endereco = address || 'Não definido';
      const clienteId = email;
      const prestadorId = 'Não definido';
      const date = new Date();
      const pagamento = 'Pendente';

      try {
          const response = await fetch(`${jobServiceUrl}/job`, {
            method: 'POST',
            headers: {
              'Content-Type': 'application/json',
              'Authorization': `Bearer ${token}`,
            },
            body: 
              JSON.stringify({ ...item, 
                  height:Number(item.height),
                  width:Number(item.width), 
                  weight:Number(item.weight),
                  depth:Number(item.depth),
                  endereco: endereco, 
                  clienteId, 
                  data: date,
                  prestadorId: prestadorId, 
                  pagamento: pagamento, 
                  valor 
              }),
          });
    
          if (!response.ok) {
            throw new Error('Failed to create job');
          }
          
          toast.success('Serviço solicitado com sucesso', {autoClose: 1500});
          setTimeout(() => {
           navigate('/request-jobs-page');
          },2000);

        } catch (error) {
          console.error('Error creating job:', error);
          toast.error('Erro ao criar serviço');
        }
  };

  const VALOR_POR_PESO = 0.01; // Altere para 0.01 para testes

  const calcularValor = (): number => {
      const { weight } = novoItem;
  
      // Se o peso não estiver definido, retorne 0
      if (weight === undefined) {
          return 0;
      }
  
      // Cálculo do valor baseado no peso
      const valorCalculado = weight * VALOR_POR_PESO;
  
      // Limitar o valor para o intervalo entre R$100 e R$1000(Altere para 0 e 10 para testes)
      const valorTotal = Math.min(Math.max(valorCalculado, 0), 1000);

      return valorTotal;
  };

  const acceptJob = async (jobId: string) => {
    try {
       const response = await fetch(`${jobServiceUrl}/job/${jobId}`, {
          method: 'PATCH',
          headers: {
             'Content-Type': 'application/json',
          },
          body: JSON.stringify({
            status: 'accepted',
            prestadorId: email,
          }),
       });
       console.log(response);
 
       if (!response.ok) {
          throw new Error('Failed to accept job');
       }
       toast.success('Coleta aceita com sucesso', {autoClose: 1500});
       setJobStatus(prevStatus => ({
        ...prevStatus,
        [jobId]: 'accepted',
      }));
    } catch (error) {
       console.error('Error accepting job:', error);
    }
  };

  const rejectJob = (jobId: string) => {
    alert(`Job ${jobId} rejeitado`);
  };

  if (loading) {
    return <div>Carregando...</div>;
  }
  
  return (
    <div className="container">
      <Header />
      <div className="home-container">
        {String(type) == '1' && isAuthenticated  ? (
          <div className="home-content">
            <h2>Selecione os Itens</h2>
            <Slider {...settings}>
              {itens.map((item) => (
                <div key={item.name} className="carousel-item">
                  <div
                    className={`item ${itensSelecionados.includes(item.name) ? 'selected' : ''}`}
                    onClick={() => selecionarItem(item.name)}
                  >
                    <FontAwesomeIcon icon={item.icon} size="3x" />
                    <h3>{item.name}</h3>
                  </div>
                </div>
              ))}
            </Slider>
            <div className="work-requests" onClick={() => window.location.href = '/request-jobs-page'}>
                <div className="work-requests-title">
                    <h5>Serviços Solicitados</h5>
                    <span>Acompanhar</span>
                </div>
                <div className="arrow-circle">
                <i className="fa-solid fa-arrow-right"></i>
                </div>
            </div>
            {isFormVisible && itensSelecionados.length > 0 && (
              <div className="custom-form">
                <h3>Descrição dos Itens Selecionados</h3>
                {itensSelecionados.map((item, index) => (
                  <div key={index} className="form-item"> 
                    <h4>{item}</h4>
                    <div className='form-group'>
                      <input type="text" placeholder="Titulo" name="title" onChange={handleChange} />
                    </div>
                    <div className="form-group">
                      <input type="number" placeholder="Peso Aproximado (kg)" name="weight" onChange={handleChange} />
                    </div>
                    <div className="form-group">
                      <textarea placeholder="Descrição Adicional (Altura, Largura e Profundidade)" name="description" onChange={handleChange}></textarea>
                    </div>
                    <div className="form-group">
                      <input readOnly type="text" placeholder={ address  || 'Digite seu endereço'} name="endereco" onChange={handleChange} />
                    </div>
                    <div className="form-group">
                      <input type="file" accept="image/*" name="imagem" onChange={handleChange} />
                    </div>
                  </div>
                ))}
                <p>Valor estimado: R$ {calcularValor()}</p>
                <button onClick={adicionarItem} disabled={isLoading} className="btn-adicionar">{ isLoading ? 'Adicionando...':' Adicionar à Lista'}</button>
              </div>
            )}
            <div className="item-box">
                <div className="item-box-title">
                    <h5>Sobre a Desentulha</h5>
                    <span>A <span className='desentulha-bold'>Desentulha</span> oferece soluções rápidas e eficazes. 
                    Nossa equipe qualificada está disponível 24 horas para resolver problemas, sempre utilizando métodos sustentáveis. 
                    Escolha a <span className='desentulha-bold'>Desentulha</span> para um serviço confiável e de qualidade.</span>
                </div>
                </div>
                <ToastContainer />
          </div>
        ) : String(type) == '2' && isAuthenticated  ? (
          <main className="">
            {filteredJobs.map((job, index) => (
              <div key={index} className="request-card">
                <h3 className='text-information-job'><strong>Titulo: </strong> {job.title}</h3>
                <p className='text-information-job'><strong>Descrição: </strong> {job.description}</p>
                <p className='text-information-job'><strong>Peso: </strong>{job.weight}kg</p>
                <p className='text-information-job'><strong>Endereço: </strong> {job.endereco}</p>
                <p className='text-information-job'><strong>Valor: </strong>R${job.valor} </p>
                <p className='text-information-job'><strong>Distância: </strong>2.5km </p>
                <div className="map-container" id={`map${index}`} style={{ height: "200px", width: "100%" }}>
                  <Map
                    address={formatAddress(job.endereco)}
                    id={`map${index}`}              
                    />
                </div>
                {jobStatus[job.id] !== 'accepted' ? (
                  <div className="action-buttons">
                    <button onClick={()=>{
                      acceptJob(job.id)
                    }} className="btn btn-adicionar">Aceitar Coleta</button>
                    <button onClick={()=>{
                      rejectJob(job.id)
                    }} className="btn btn-outline-danger">Recusar</button>
                  </div>
                ):(
                  <div className="action-buttons align-center d-flex justify-content-center">
                    <button className="btn btn-success" disabled>Aceito</button>
                  </div>
                )}
              </div>
            ))}
          </main>
        ) : null}
      </div>
      <Footer />
    </div>
  );
};

export default Home;
