import React, { useState, useEffect } from "react";
import { getServicos,  authenticateWithEfiBank, createPixCharge, transferToPrestador  } from "../services/jobService";
import { Loader } from "@googlemaps/js-api-loader";
import 'bootstrap/dist/css/bootstrap.min.css';
import '@fortawesome/fontawesome-free/css/all.min.css';
import { useNavigate } from "react-router-dom";  // Para redirecionamento
import { QRCodeCanvas } from 'qrcode.react';
import { io } from "socket.io-client"; // Importa o cliente do Socket.IO

const Servicos: React.FC = () => {
  const navigate = useNavigate();  // Hook de navegação
  const loader = new Loader({
    apiKey: process.env.REACT_APP_API_KEY_MAPS || "",  // Chave da API do Google Maps
    version: "weekly",
  });
  const [servicos, setServicos] = useState<any[]>([]);
  const [cep, setCep] = useState("");
  const [addressData, setAddressData] = useState({
    logradouro: "",
    bairro: "",
    localidade: "",
    uf: "",
    pais: "Brasil",
    numero: "",
    complemento: "",
    tipoResidencia: "casa",
  });
  const [location, setLocation] = useState({ latitude: 0, longitude: 0 });
  const [qrCodeUrl, setQrCodeUrl] = useState<string | null>(null); // Estado para armazenar a URL do QR code
  const [mostrarComplemento, setMostrarComplemento] = useState(false);
  const [pagamentoConfirmado, setPagamentoConfirmado] = useState<boolean>(false);
  const [mapaVisivel, setMapaVisivel] = useState(false); // Controla a exibição do mapa
  const type = localStorage.getItem("type");
  const email = localStorage.getItem("email"); // Email do usuário logado
  const accessTokenLocal = localStorage.getItem("access_token");
  const [accessToken, setAccessToken] = useState<string | null>(null);
  const [base64QrCode, setBase64QrCode] = useState<string | null>(null);
  const userServiceUrl = process.env.REACT_APP_USER_SERVICE_URL || 'http://localhost:3001';
  const jobServiceUrl = process.env.REACT_APP_JOB_SERVICE_URL || 'http://localhost:3002';
  const paymentServiceUrl = process.env.REACT_APP_PAYMENT_SERVICE_URL || 'http://localhost:3003';
  
  useEffect(() => {
    if (!accessTokenLocal) {
      navigate("/"); // Redireciona para a página de login
    }
  }, [accessTokenLocal, navigate]);

  useEffect(() => {
    const socket = io("http://localhost:3005"); // Conectar ao WebSocket
  
    socket.on("connect", () => {
      console.log("Conectado ao WebSocket");
    });
  
    socket.on("disconnect", () => {
      console.log("Desconectado do WebSocket");
    });
  
    // Ouvir o evento de pagamento confirmado
    socket.on("paymentConfirmed", (updatedJob) => {
      console.log("Evento paymentConfirmed recebido:", updatedJob);
    
      // Atualiza o estado dos serviços quando o pagamento é confirmado
      setServicos((prevServicos) => {
        const servicosAtualizados = prevServicos.map((servico) =>
          servico.id === updatedJob.id ? { ...servico, pagamento: "pago" } : servico
        );
    
        return [...servicosAtualizados];
      });
      
      // Se o job confirmado for o atual exibido
      if (updatedJob.id) {
        setPagamentoConfirmado(true);  // Marca como pagamento confirmado para esconder o QR code
      }
      
    });
    
    return () => {
      socket.disconnect(); // Desconectar o WebSocket ao desmontar o componente
    };
  }, []);
  
  useEffect(() => {
    if (qrCodeUrl) {
      localStorage.setItem('qrCodeUrl', qrCodeUrl); // Salva o valor no localStorage
    }
  }, [qrCodeUrl]);
  
  useEffect(() => {
    const savedQrCodeUrl = localStorage.getItem('qrCodeUrl'); // Recupera o valor do localStorage na inicialização
    if (savedQrCodeUrl) {
      setQrCodeUrl(savedQrCodeUrl);
    }
  }, []);
  

  async function getAddressByCep(cep: string): Promise<any> {
    const url = `https://viacep.com.br/ws/${cep}/json/`;

    try {
      const response = await fetch(url);

      if (!response.ok) {
        throw new Error("Erro ao buscar o CEP");
      }

      const data = await response.json();

      if (data.erro) {
        throw new Error("CEP inválido");
      }

      setAddressData({
        ...addressData,
        logradouro: data.logradouro,
        bairro: data.bairro,
        localidade: data.localidade,
        uf: data.uf,
      });
    } catch (error) {
      console.error(`Erro: ${error}`);
    }
  }

  // Função para buscar coordenadas usando OpenStreetMap (Nominatim)
  async function getCoordinatesByAddress(address: string): Promise<any> {
    const url = `https://nominatim.openstreetmap.org/search?q=${encodeURIComponent(address)}&format=json&addressdetails=1`;

    try {
      const response = await fetch(url);
      const data = await response.json();

      if (data && data.length > 0) {
        setLocation({
          latitude: parseFloat(data[0].lat),
          longitude: parseFloat(data[0].lon),
        });
        setMapaVisivel(true);
      } else {
        throw new Error("Endereço não encontrado");
      }
    } catch (error) {
      console.error("Erro ao obter coordenadas:", error);
    }
  }

  // Função para solicitar o serviço e gerar cobrança PIX
  const handleBuscarEndereco = async () => {
    await getAddressByCep(cep);
  };

  // Função para exibir o mapa com a localização do endereço
  const handleExibirMapa = async () => {
    const enderecoCompleto = `${addressData.logradouro}, ${addressData.numero}, ${addressData.bairro}, ${addressData.localidade}, ${addressData.uf}, ${addressData.pais}`;
    await getCoordinatesByAddress(enderecoCompleto);
  };

  // Função para carregar a lista de serviços solicitados
  async function loadWorks() {
    const data = await getServicos();
    setServicos(data);
  }

  // Função para solicitar o serviço e gerar cobrança PIX
  async function workRequest() {
    let valorServico: number = 0.10;
    try {
      const response = await fetch(`${jobServiceUrl}/job`, {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${accessTokenLocal}`,
        },
        body: JSON.stringify({
          endereco: "Endereço completo",
          clienteId: email,
          prestadorId: "não definido",
          status: "pendente",
          data: new Date(),
          pagamento: "pendente",
          valor: 0.05,
        }),
      });
  
      const data = await response.json();
  
      console.log("QR Code para pagamento:", data);
      if (data && data.qrCodeUrl) {
        setQrCodeUrl(data.qrCodeUrl.pixCopiaECola);
      }
  
      console.log("Serviço solicitado com sucesso:", data);
      loadWorks();
    } catch (error) {
      console.error("Erro ao solicitar o serviço ou criar cobrança:", error);
    }
  }
  
  // Função para finalizar o serviço e transferir o valor para o prestador
  async function finishService(id: number, prestadorPixKey: string, valorServico: number) {
    try {
      // Realiza a transferência para o prestador
      console.log("Transferência realizada com sucesso");
  
      const response = await fetch(`${jobServiceUrl}/job/${id}`, {
        method: "PUT",
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${accessTokenLocal}`,
        },
        body: JSON.stringify({
          status: "finalizado",
        }),
      });
  
      if (response.ok) {
        console.log("Serviço finalizado com sucesso");
        loadWorks();
      } else {
        console.error("Erro ao finalizar o serviço");
      }
    } catch (error) {
      console.error("Erro ao finalizar o serviço ou transferir o valor:", error);
    }
  }
  
  // Função para aceitar o serviço e atualizar o status
  async function acceptService(id: number) {
    try {
      const response = await fetch(`${jobServiceUrl}/job/${id}/accept`, {
        method: "PUT",
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${accessTokenLocal}`,
        },
        body: JSON.stringify({
          prestadorId: email,
          status: "aceito",
        }),
      });
  
      if (response.ok) {
        loadWorks(); // Atualiza a lista de serviços após a aceitação
        alert("Serviço aceito com sucesso!");
      } else {
        console.error("Erro ao aceitar o serviço");
      }
    } catch (error) {
      console.error("Erro ao aceitar o serviço:", error);
    }
  }
  
  // Carregar os serviços solicitados na montagem do componente
  useEffect(() => {
    if (accessTokenLocal) {
      loadWorks();
    }

    if (location.latitude !== 0 && location.longitude !== 0) {
      loader.load().then((google) => {
        const map = new google.maps.Map(document.getElementById("map") as HTMLElement, {
          center: { lat: location.latitude, lng: location.longitude },
          zoom: 15,
        });

        new google.maps.Marker({
          position: { lat: location.latitude, lng: location.longitude },
          map: map,
          title: "Localização Encontrada",
        });
      });
    }
  }, [location, accessTokenLocal]);

  // Função para atualizar o tipo de residência e mostrar o campo de complemento
  const handleTipoResidenciaChange = (tipo: string) => {
    setAddressData({ ...addressData, tipoResidencia: tipo });
    setMostrarComplemento(tipo === "apartamento");
  };
  
  return (
    <div className="container mt-5">
      {type === "1" ? (
        <>
        {/* Formulário de solicitação de serviço */}
        <div className="row mb-4">
          <div className="col text-center">
            <h2 className="fw-bold">Solicitar Serviço</h2>
            <p className="text-muted">Insira seu CEP para buscar o endereço e solicitar o serviço</p>
          </div>
        </div>
        <div className="row mb-4 justify-content-center">
          <div className="col-md-6 text-center">
            <div className="input-group">
              <input
                type="text"
                value={cep}
                className="form-control"
                placeholder="Digite seu CEP"
                onChange={(e) => setCep(e.target.value)}
              />
              <button type="button" className="btn btn-primary input-group-text" onClick={handleBuscarEndereco}>
                <i className="fas fa-search"></i> Buscar
              </button>
            </div>
          </div>
        </div>
        <form>
          <div className="row mb-3 justify-content-center">
            <div className="col-md-6">
              <div className="input-group mb-3">
                <span className="input-group-text">
                  <i className="fas fa-map-marker-alt"></i>
                </span>
                <input
                  type="text"
                  className="form-control"
                  placeholder="Logradouro"
                  value={addressData.logradouro}
                  readOnly
                />
              </div>
              <div className="input-group mb-3">
                <span className="input-group-text">
                  <i className="fas fa-building"></i>
                </span>
                <input
                  type="text"
                  className="form-control"
                  placeholder="Bairro"
                  value={addressData.bairro}
                  readOnly
                />
              </div>
              <div className="input-group mb-3">
                <span className="input-group-text">
                  <i className="fas fa-city"></i>
                </span>
                <input
                  type="text"
                  className="form-control"
                  placeholder="Cidade"
                  value={addressData.localidade}
                  readOnly
                />
              </div>
              <div className="input-group mb-3">
                <span className="input-group-text">
                  <i className="fas fa-flag"></i>
                </span>
                <input
                  type="text"
                  className="form-control"
                  placeholder="Estado"
                  value={addressData.uf}
                  readOnly
                />
              </div>
              <div className="input-group mb-3">
                <span className="input-group-text">
                  <i className="fas fa-home"></i>
                </span>
                <input
                  type="text"
                  className="form-control"
                  placeholder="Número"
                  value={addressData.numero}
                  onChange={(e) => setAddressData({ ...addressData, numero: e.target.value })}
                />
              </div>
              <div className="form-check form-switch mb-3">
                <input
                  type="checkbox"
                  className="form-check-input"
                  checked={addressData.tipoResidencia === "apartamento"}
                  onChange={() => handleTipoResidenciaChange(addressData.tipoResidencia === "casa" ? "apartamento" : "casa")}
                />
                <label className="form-check-label">Apartamento</label>
              </div>
              {mostrarComplemento && (
                <div className="input-group mb-3">
                  <span className="input-group-text">
                    <i className="fas fa-info-circle"></i>
                  </span>
                  <input
                    type="text"
                    className="form-control"
                    placeholder="Complemento"
                    value={addressData.complemento}
                    onChange={(e) => setAddressData({ ...addressData, complemento: e.target.value })}
                  />
                </div>
              )}
              <div className="text-center mt-4">
                <button type="button" className="btn btn-info" onClick={handleExibirMapa}>
                  <i className="fas fa-map"></i> Exibir Mapa
                </button>
              </div>
              {mapaVisivel && (
                <>
                  <div className="mt-4" id="map" style={{ height: "400px", backgroundColor: "#f5f5f5" }}></div>
                  <div className="text-center mt-4">
                    <button
                      type="submit"
                      className="btn btn-success"
                      onClick={(e) => {
                        e.preventDefault();
                        workRequest();
                        alert("Solicitação enviada com sucesso!");
                      }}
                    >
                      <i className="fas fa-check"></i> Solicitar Serviço
                    </button>
                  </div>
                </>
              )}
            </div>
          </div>
        </form>
        {/* Exibição dos serviços solicitados pelo usuário do tipo 1 */}
        <div className="row">
          <h3 className="text-center my-4">Meus Serviços Solicitados</h3>
          <div className="row justify-content-center">
            {servicos
              .filter((servico) => servico.clienteId === email) 
              .map((servico) => (
                <div className="col-md-4 d-flex justify-content-center" key={servico.id}>
                  <div className="card mb-3 border-primary" style={{ width: "18rem" }}>
                    <div className="card-body">
                      <h5 className="card-title">Serviço ID: {servico.id}</h5>
                      <p className="card-text"><strong>Endereço:</strong> {servico.endereco}</p>
                      <p className="card-text"><strong>Status:</strong> {servico.status}</p>
                      <p className="card-text"><strong>Pagamento:</strong> {servico.pagamento}</p>
                     {servico.pagamento === "pendente" && !pagamentoConfirmado ? (
                        <div className="text-center mb-4">
                          <h5>Escaneie o QR Code para realizar o pagamento:</h5>
                          <QRCodeCanvas
                            value={servico.qrCodeUrl || qrCodeUrl} 
                            size={256}
                            level={"H"}
                            includeMargin={true}
                          />
                        </div>
                      ) : (
                        <div className="badge bg-success">
                          Pagamento Confirmado
                        </div>
                      )}
                    </div>
                  </div>
                </div>
              ))}
          </div>
        </div>
      </>

      ) : (
        <div>
          <h2>Lista de Serviços</h2>
          <div className="container">
            {/* Seção de Serviços Aceitos */}
            {servicos.some((servico) => servico.status === "aceito") && (
              <>
                <h3 className="text-center my-4">Serviços Aceitos</h3>
                <div className="row justify-content-center">
                  {servicos
                    .filter((servico) => servico.status === "aceito")
                    .map((servico) => (
                      <div className="col-md-4 d-flex justify-content-center" key={servico.id}>
                        <div className="card mb-3 border-success" style={{ width: "18rem" }}>
                          <div className="card-body">
                            <h5 className="card-title">Serviço ID: {servico.id}</h5>
                            <p className="card-text"><strong>Endereço:</strong> {servico.endereco}</p>
                            <p className="card-text"><strong>Cliente:</strong> {servico.clienteId}</p>
                            <p className="card-text"><strong>Prestador:</strong> {servico.prestadorId || "N/A"}</p>
                            <p className="card-text"><strong>Data:</strong> {new Date(servico.data).toLocaleDateString()}</p>
                            <p className="card-text"><strong>Pagamento:</strong> {servico.pagamento}</p>
                            <div className="badge bg-success">
                              Aceito
                            </div>
                            <button className="btn btn-success mt-3 w-100"
                              onClick={() => finishService(servico.id,"12432010612",10)}>
                              Finalizar Serviço
                            </button>
                          </div>
                        </div>
                      </div>
                    ))}
                </div>
              </>
            )}
            {/* Seção de Serviços Pendentes */}
            {servicos.some((servico) => servico.status === "pendente" && servico.pagamento === "pago") && (
              <>
                <h3 className="text-center my-4">Serviços Pendentes</h3>
                <div className="row justify-content-center">
                  {servicos
                    .filter((servico) => servico.status === "pendente" && servico.pagamento === "pago")
                    .map((servico) => (
                      <div className="col-md-4 d-flex justify-content-center" key={servico.id}>
                        <div className="card mb-3 border-warning" style={{ width: "18rem" }}>
                          <div className="card-body">
                            <h5 className="card-title">Serviço ID: {servico.id}</h5>
                            <p className="card-text"><strong>Endereço:</strong> {servico.endereco}</p>
                            <p className="card-text"><strong>Cliente:</strong> {servico.clienteId}</p>
                            <p className="card-text"><strong>Prestador:</strong> {servico.prestadorId || "N/A"}</p>
                            <p className="card-text"><strong>Data:</strong> {new Date(servico.data).toLocaleDateString()}</p>
                            <p className="card-text"><strong>Pagamento:</strong> {servico.pagamento}</p>

                            <div className="badge bg-warning">
                              Pendente
                            </div>

                            <button className="btn btn-warning mt-3 w-100"
                              onClick={() => acceptService(Number(servico.id))}>
                              Aceitar Serviço
                            </button>
                          </div>
                        </div>
                      </div>
                    ))}
                </div>
              </>
            )}
            {servicos.some((servico) => servico.status === "cancelado") && (
              <>
                <h3 className="text-center my-4">Serviços Cancelados</h3>
                <div className="row justify-content-center">
                  {servicos
                    .filter((servico) => servico.status === "cancelado")
                    .map((servico) => (
                      <div className="col-md-4 d-flex justify-content-center" key={servico.id}>
                        <div className="card mb-3 border-danger" style={{ width: "18rem" }}>
                          <div className="card-body">
                            <h5 className="card-title">Serviço ID: {servico.id}</h5>
                            <p className="card-text"><strong>Endereço:</strong> {servico.endereco}</p>
                            <p className="card-text"><strong>Cliente:</strong> {servico.clienteId}</p>
                            <p className="card-text"><strong>Prestador:</strong> {servico.prestadorId || "N/A"}</p>
                            <p className="card-text"><strong>Data:</strong> {new Date(servico.data).toLocaleDateString()}</p>
                            <p className="card-text"><strong>Pagamento:</strong> {servico.pagamento}</p>
                            <div className="badge bg-danger">
                              Cancelado
                            </div>
                            <button className="btn btn-danger mt-3 w-100" disabled>
                              Serviço Cancelado
                            </button>
                          </div>
                        </div>
                      </div>
                    ))}
                </div>
              </>
            )}
            {servicos.some((servico) => servico.status === "finalizado") && (
              <>
                <h3 className="text-center my-4">Serviços Finalizados</h3>
                <div className="row justify-content-center">
                  {servicos
                    .filter((servico) => servico.status === "finalizado")
                    .map((servico) => (
                      <div className="col-md-4 d-flex justify-content-center" key={servico.id}>
                        <div className="card mb-3 border-primary" style={{ width: "18rem" }}>
                          <div className="card-body">
                            <h5 className="card-title">Serviço ID: {servico.id}</h5>
                            <p className="card-text"><strong>Endereço:</strong> {servico.endereco}</p>
                            <p className="card-text"><strong>Cliente:</strong> {servico.clienteId}</p>
                            <p className="card-text"><strong>Prestador:</strong> {servico.prestadorId || "N/A"}</p>
                            <p className="card-text"><strong>Data:</strong> {new Date(servico.data).toLocaleDateString()}</p>
                            <p className="card-text"><strong>Pagamento:</strong> {servico.pagamento}</p>
                            <div className="badge bg-primary">
                              Finalizado
                            </div>
                          </div>
                        </div>
                      </div>
                    ))}
                </div>
              </>
            )}
          </div>
        </div>
      )}
    </div>
  );
};

export default Servicos;
