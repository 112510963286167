import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { toast, ToastContainer } from "react-toastify";
import api_user_service from "../api/api";
import User from "../types/UserRegister";
import 'react-toastify/dist/ReactToastify.css';
import "../styles/Cadastro.css";

const useDebounce = (value: string, delay: number) => {
  const [debouncedValue, setDebouncedValue] = useState(value);

  useEffect(() => {
    const handler = setTimeout(() => {
      setDebouncedValue(value);
    }, delay);

    return () => {
      clearTimeout(handler);
    };
  }, [value, delay]);

  return debouncedValue;
};

const Register: React.FC = () => {
  const [user, setUser] = useState<User>({
    name: "",
    email: "",
    password: "",
    type: "1",
    bornDate: new Date(),
    phone: "",
    address: "",
    cpf: "",
    saldo: 0,
    cep: "",
    street: "",
    city: "",
    state: "",
    apartment: "", 
    residenceType: "", 
    number: "", 
    logradouro: "",
    bairro: "",
    localidade: "",
    uf: "",
    pais: "Brasil",
  });
  const [step, setStep] = useState(1);
  const [cep, setCep] = useState<string>(""); 
  const debouncedCep = useDebounce(cep, 1000);
  const navigate = useNavigate();

  useEffect(() => {
    if (debouncedCep.length) {
      getAddressByCep(debouncedCep);
    }
  }, [debouncedCep]);


  async function getAddressByCep(cep: string): Promise<void> {
    const url = `https://viacep.com.br/ws/${cep}/json/`;

    try {
      const response = await fetch(url);

      if (!response.ok) {
        throw new Error("Erro ao buscar o CEP");
      }

      const data = await response.json();

      if (data.erro) {
        throw new Error("CEP inválido");
      }
      setUser((prevUser) => ({
        ...prevUser,
        street: data.logradouro,
        city: data.localidade,
        state: data.uf,
        cep: data.cep, 
        bairro: data.bairro,
        pais: prevUser.pais || "Brasil"
      }));
    } catch (error) {
      console.error(`Erro: ${error}`);
      toast.error("Erro ao buscar o endereço. Verifique o CEP informado.");
    }
  }

  const handleCepChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const value = e.target.value
    setCep(value);
    setUser((prevUser) => ({
      ...prevUser,
      cep: value,
    }));
  };

  const handleChange = (e: React.ChangeEvent<HTMLInputElement> | React.ChangeEvent<HTMLSelectElement>) => {
    const { name, value } = e.target;
    setUser((prevUser) => ({
      ...prevUser,
      [name]: value,
    }));
  };

  const handleSubmit = (e: React.FormEvent<HTMLFormElement>) => {
    e.preventDefault();
    if (step === 2) {
      handleAddUser();
    } else {
      if (user.name && user.email && user.password && user.phone && user.type) {
        setStep(step + 1);
      } else {
        toast.error("Por favor, preencha todos os campos obrigatórios.");
      }
    }
  };

  const handleAddUser = async () => {
    const fullAddress = `${user.street}, ${user.number}, ${user.bairro}, ${user.city}, ${user.state}, ${user.pais} ${user.apartment ? `, Apt ${user.apartment}` : ''}, ${user.cep}`;

    const userToSubmit = {
      ...user,
      address: fullAddress,
    };
  
    try {
      const response = await api_user_service.post("usuario", userToSubmit, {
        headers: {
          "Content-Type": "application/json",
        },
        withCredentials: true,
      });
  
      if (response.status === 201) {
        alert("Usuário cadastrado com sucesso!");
        navigate("/");
      }
    } catch (error) {
      console.error("Erro ao cadastrar usuário:", error);
      toast.error("Erro ao cadastrar usuário. Verifique as informações e tente novamente.");
    }
  };

  return (
    <div className="container d-flex justify-content-center align-items-center min-vh-100">
      <div className="card shadow-lg p-4" style={{ width: "400px", borderRadius: "15px" }}>
        <ToastContainer />
        <h2 className="text-center mb-4">Cadastro de Usuário</h2>
        <form onSubmit={handleSubmit}>
          {step === 1 && (
            <>
              <div className="mb-3">
                <label className="d-flex mb-1">Tipo de Usuário</label>
                <select name="type" value={user.type} onChange={handleChange} className="form-select" required>
                  <option value={1}>Cliente</option>
                  <option value={2}>Prestador</option>
                </select>
              </div>

              <div className="mb-3">
                <label className="d-flex">Nome</label>
                <input
                  type="text"
                  name="name"
                  value={user.name}
                  onChange={handleChange}
                  required
                  className="form-control"
                  placeholder="Digite seu nome"
                />
              </div>

              <div className="mb-3">
                <label className="d-flex mb-1">Email</label>
                <input
                  type="email"
                  name="email"
                  value={user.email}
                  onChange={handleChange}
                  required
                  className="form-control"
                  placeholder="Digite seu email"
                />
              </div>

              <div className="mb-3">
                <label className="d-flex mb-1">Senha</label>
                <input
                  type="password"
                  name="password"
                  value={user.password}
                  onChange={handleChange}
                  required
                  className="form-control"
                  placeholder="Digite sua senha"
                />
              </div>

              <div className="mb-3">
                <label className="d-flex mb-1">CPF</label>
                <input
                  type="text"
                  name="cpf"
                  value={user.cpf}
                  onChange={handleChange}
                  required
                  className="form-control"
                  placeholder="Digite seu CPF"
                />
              </div>


              <div className="mb-3">
                <label className="d-flex mb-1">Telefone</label>
                <input
                  type="text"
                  name="phone"
                  value={user.phone}
                  onChange={handleChange}
                  required
                  className="form-control"
                  placeholder="Digite seu telefone"
                />
              </div>
            </>
          )}

          {step === 2 && (
            <>
              <div className="mb-3">
                <label className="d-flex mb-1">CEP</label>
                <input
                  type="text"
                  name="cep"
                  value={user.cep}
                  onChange={handleCepChange}
                  required
                  className="form-control"
                  placeholder="Digite seu CEP"
                />
              </div>
              <div className="mb-3">
                <label className="d-flex mb-1">Bairro</label>
                <input
                    type="text"
                    name="bairro"
                    value={user.bairro}
                    onChange={handleChange}
                    className="form-control"
                    placeholder="Digite o bairro"
                />
              </div>
              <div className="mb-3">
                <label className="d-flex mb-1">Logradouro</label>
                <input
                    type="text"
                    name="street"
                    value={user.street}
                    onChange={handleChange}
                    className="form-control"
                    placeholder="Digite o logradouro"
                />
            </div>
            <div className="mb-3">
                <label className="d-flex mb-1">Cidade</label>
                <input
                    type="text"
                    name="city"
                    value={user.city}
                    onChange={handleChange}
                    className="form-control"
                    placeholder="Digite a cidade"
                />
            </div>
            <div className="mb-3">
                <label className="d-flex mb-1">Estado</label>
                <input
                    type="text"
                    name="state"
                    value={user.state}
                    onChange={handleChange}
                    className="form-control"
                    placeholder="Digite o estado"
                />
            </div>
            <div className="mb-3">
                <label className="d-flex mb-1">País</label>
                <input
                    type="text"
                    name="pais"
                    value={user.pais}
                    onChange={handleChange}
                    className="form-control"
                    placeholder="Digite o país"
                />
            </div>
              <div className="mb-3">
                <label className="d-flex mb-1">Número</label>
                <input
                  type="text"
                  name="number"
                  value={user.number}
                  onChange={handleChange}
                  className="form-control"
                  placeholder="Digite o número da residência"
                />
              </div>
              
              <div className="mb-3">
                <label className="d-flex mb-1">Tipo de Residência</label>
                <select
                  name="residenceType"
                  value={user.residenceType}
                  onChange={handleChange}
                  className="form-select"
                  required
                >
                  <option value="casa">Casa</option>
                  <option value="apartamento">Apartamento</option>
                </select>
              </div>

              {user.residenceType === "apartamento" && (
                <div className="mb-3">
                  <label className="d-flex mb-1">Número do Apartamento</label>
                  <input
                    type="text"
                    name="apartment"
                    value={user.apartment}
                    onChange={handleChange}
                    className="form-control"
                    placeholder="Digite o número do apartamento"
                  />
                </div>
              )}
            </>
          )}

          <button type="submit" className="btn button-color w-100">
            {step === 1 ? "Próximo Passo" : "Cadastrar"}
          </button>
        </form>
      </div>
    </div>
  );
};

export default Register;