import { useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import { useAuth } from '../contexts/AuthProvider';
import Header from '../components/Header';
import Footer from '../components/Footer';
import '../styles/SearchStyles.css';

const SearchPage: React.FC = () => {
  const { user, isAuthenticated, loading } = useAuth();
  const navigate = useNavigate();

  useEffect(() => {
    if (!loading && !isAuthenticated) {
      navigate('/');
    }
  }, [isAuthenticated, navigate]);
  
  return (
    <div className="container-search-page">
        <Header />
        <h1>Search</h1>
        <Footer />
    </div>
  );
};

export default SearchPage;