import React, { createContext, useContext, useEffect, useState } from 'react';
import Cookies from 'js-cookie';
import { jwtDecode } from "jwt-decode";
import User from '../types/UserAuthProviderTypes';
import AuthContextProps from '../types/AuthContextProps';
import AuthProviderProps from '../types/AuthProviderProps';

const AuthContext = createContext<AuthContextProps | undefined>(undefined);

export const AuthProvider: React.FC<AuthProviderProps> = ({ children }) => {
  const [user, setUser] = useState<User | null>(null);
  const [loading, setLoading] = useState(true);

  const login = (token: string) => {
    Cookies.set('access_token', token, { secure: true, sameSite: 'Strict' });
    const decodedUser = jwtDecode<{ email: string; type: number }>(token);
    setUser(decodedUser);
  };

  const logout = () => {
    Cookies.remove('access_token');
    setUser(null);
  };

  useEffect(() => {
    const token = Cookies.get('access_token');
    if (token) {
      try {
        const decodedUser = jwtDecode<{ email: string; type: number }>(token);
        setUser(decodedUser);
      } catch (error) {
        console.error('Invalid token', error);
        logout();
      }
    }
    setLoading(false);
  }, []);

  return (
    <AuthContext.Provider value={{ user, isAuthenticated: !!user, loading, login, logout }}>
      {children}
    </AuthContext.Provider>
  );
};

export const useAuth = () => {
  const context = useContext(AuthContext);
  if (context === undefined) {
    throw new Error('useAuth must be used within an AuthProvider');
  }
  return context;
};
