import React, { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { useAuth } from '../contexts/AuthProvider';
import Header from '../components/Header';
import Footer from '../components/Footer';
import '../styles/ProfileStyles.css';

const ProfilePage: React.FC = () => {
  const [ userName, setUserName ] = useState<string>('');
  const { user, isAuthenticated, loading } = useAuth();
  const { logout } = useAuth(); 
  const navigate = useNavigate();

  const email = String(user?.email);
  const type = user?.type;

  useEffect(() => {
    if (!loading && !isAuthenticated) {
      navigate('/');
    } else {
      checkEmailAndType(email, type);
    }
  }, [navigate,email, type, isAuthenticated, loading]);

  function checkEmailAndType(email: string, type: number | undefined): void {
    if (email && type) {
      setUserName(user?.name || '');
    }
  }

  function goToHistory(): void {
    navigate('/request-jobs-page');
  }

  function goToHome(): void {
    navigate('/home');
  }

  function handleLogout(): void {
    logout();
    navigate('/');
  }

  //TODO: Implementar a funcionalidade de itens desentulhados, pontos e certificados
  //TODO: Implementar a funcionalidade de nivel de desentulhador

  return (
    <div className="container-profile-page">
      <Header />
      <main className="main-content">
        <section className="profile-section">
          <div className="profile-header">
            <div className="profile-pic">
              <i className="fas fa-user"></i>
            </div>
            <div className="profile-info">
              <h2>{userName || 'Usuário'}</h2>
              <p>Desentulhador Nível Ouro</p>
            </div>
          </div>
          <div className="profile-stats">
            <div className="stat-box">
              <div className="stat-number">127</div>
              <div className="stat-label">Itens Desentulhados</div>
            </div>
            <div className="stat-box">
              <div className="stat-number">12</div>
              <div className="stat-label">Pontos</div>
            </div>
            <div className="stat-box">
              <div className="stat-number">5</div>
              <div className="stat-label">Certificados</div>
            </div>
          </div>
          <div className="profile-actions">
            <button onClick={goToHome} className="action-button">Desentulhar Mais</button>
            <button onClick={goToHistory} className="action-button">Ver Histórico</button>
            <div className="profile-logout">
              <button onClick={handleLogout} className="logout-button">Sair</button>
            </div>
          </div>
        </section>
      </main>
      <Footer />
    </div>
  );
};

export default ProfilePage;
