const API_URL = 'http://localhost:3001'; 

export const getServicos = async (): Promise<any[]> => {
  const response = await fetch(`${API_URL}/job`);
  return response.json();
};

// Função para autenticar com o EFI Bank
export async function authenticateWithEfiBank() {
  const url = 'https://api.efipay.com.br/v1/auth/token';
  const response = await fetch(url, {
    method: 'POST',
    headers: {
      'Content-Type': 'application/json',
    },
    body: JSON.stringify({
      client_id: 'Client_Id_f6c7048ebc5a9a67a268f066b4d3beabb02cd76f',
      client_secret: 'Client_Secret_debf4ede0471a5b3783f934cf361cd4e16f6b9d9',
    }),
  });

  const data = await response.json();
  if (!response.ok) {
    throw new Error('Erro ao autenticar com EFI Bank');
  }

  return data.access_token;
}

// Função para criar uma cobrança PIX
export async function createPixCharge(servicoId: string, valorTotal: number, accessToken: string, cliente:{cpf: string, nome: string}) {
  const url = 'https://api.efipay.com.br/v1/pix/cob';
  const response = await fetch(url, {
    method: 'POST',
    headers: {
      'Content-Type': 'application/json',
      Authorization: `Bearer ${accessToken}`,
    },
    body: JSON.stringify({
      calendario: {
        expiracao: 3600, // 1 hora de expiração
      },
      devedor: {
        cpf: cliente.cpf, // CPF do cliente
        nome: cliente.nome, // Nome do cliente
      },
      valor: {
        original: valorTotal.toFixed(2),
      },
      chave: '12432010612',
      solicitacaoPagador: 'Pagamento do serviço solicitado.',
    }),
  });

  const data = await response.json();
  if (!response.ok) {
    throw new Error('Erro ao criar cobrança PIX');
  }

  return data.loc; // Retorna o QR code para pagamento
}

// Função para transferir para o prestador via PIX
export async function transferToPrestador(prestadorPixKey: string, valorTotal: number, accessToken: string) {
  const valorPrestador = valorTotal * 0.7; // Calcula 70% para o prestador

  const url = 'https://api.efipay.com.br/v1/pix/txid/{txid}'; // Endpoint para transferência via PIX
  const response = await fetch(url, {
    method: 'POST',
    headers: {
      'Content-Type': 'application/json',
      Authorization: `Bearer ${accessToken}`,
    },
    body: JSON.stringify({
      valor: valorPrestador.toFixed(2),
      chave: prestadorPixKey,
      descricao: 'Pagamento pelo serviço prestado.',
    }),
  });

  if (!response.ok) {
    throw new Error('Erro ao transferir para o prestador');
  }

  return await response.json(); // Retorna os dados da transferência
}
