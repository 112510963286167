import React, { useEffect, useState, useRef } from 'react';
import { useNavigate } from 'react-router-dom';
import { faCartArrowDown, faTrash, faCopy, faCheck } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { Modal } from 'react-bootstrap';
import { QRCodeCanvas } from 'qrcode.react';
import { toast, ToastContainer } from 'react-toastify';
import { io } from 'socket.io-client';
import { useAuth } from '../contexts/AuthProvider';
import Cookies from 'js-cookie';
import Header from '../components/Header';
import Footer from '../components/Footer';
import Job from '../types/Job';
import '../styles/RequestJobsStyles.css';

const socket = io(process.env.REACT_APP_PAYMENT_SERVICE_URL)

const RequestJobsPage: React.FC = () => {
  const [ jobs, setJobs ] = useState<Job[]>([]);
  const [ showModal, setShowModal ] = useState(false);
  const [ selectedJob, setSelectedJob ] = useState<Job | null>(null);
  const [ qrCodeUrl, setQrCodeUrl ] = useState<string | null>(null);
  const [ selectedJobTxId, setSelectedJobTxId ] = useState<string | null>(null);
  const [ pixCode, setPixCode ] = useState<string | null>(null);
  const [ paymentConfirmed, setPaymentConfirmed ] = useState<boolean>(false)
  const { user, isAuthenticated, loading } = useAuth();
  const token = Cookies.get('access_token');
  const pixInputRef = useRef<HTMLInputElement>(null);
  const navigate = useNavigate();

  useEffect(() => {
    if (selectedJob) {
      setSelectedJobTxId(selectedJob.txId); 
    }
  }, [selectedJob]);
  
  useEffect(() => {
    if (!loading && isAuthenticated) {
      fetchJobs();
    } else if (!loading && !isAuthenticated) {
      navigate('/');
    }
    socket.connect();
    
    socket.on('connect', () => {
      console.log('Socket conectado');
    });

    socket.on('paymentConfirmed', (data) => {
      setJobs((prevJobs) =>
        prevJobs.map((job) => 
          job.txId == data.txid 
            ? { ...job, pagamento: 'feito', pixCopiaECola: null } 
            : job
        )
      );

      if (data.txid) {
        setSelectedJob((prev) => (prev ? { ...prev, pagamento: 'feito' } : null));
        setPaymentConfirmed(true);
        setQrCodeUrl(null);
      }
    });
    
    return () => {
      socket.off('paymentConfirmed');
      socket.disconnect();
    };
  }, [loading, navigate, isAuthenticated]);

  useEffect(() => {
    const handleVisibilityChange = () => {
      if (document.visibilityState === 'visible') {
        checkPaymentStatus();
      }
    };

    document.addEventListener('visibilitychange', handleVisibilityChange);

    return () => {
      document.removeEventListener('visibilitychange', handleVisibilityChange);
    };
  }, [selectedJobTxId]);

  const checkPaymentStatus = async () => {
    if (selectedJobTxId) {
      try {
        const response = await fetch(
          `${process.env.REACT_APP_JOB_SERVICE_URL}/job/status?txId=${selectedJobTxId}`,
          {
            method: 'GET',
            headers: {
              'Authorization': `Bearer ${token}`,
              'Content-Type': 'application/json',
            },
          }
        );
  
        const textResponse = await response.text();
  
        if (!response.ok) {
          console.error('Erro detalhado:', textResponse);
          throw new Error('Erro ao verificar status do pagamento');
        }else{
          console.log('Pagamento confirmado');
        }

      } catch (error) {
        console.error('Erro ao verificar o status do pagamento:', error);
      }
    }
  };

  const fetchJobs = async () => {
    if (user && user.email) {
      try {
        const response = await fetch(`${process.env.REACT_APP_JOB_SERVICE_URL}/job?clienteId=${user.email}`, {
          method: 'GET',
          headers: {
            'Authorization': `Bearer ${token}`,
            'Content-Type': 'application/json',
          },
        });

        if (!response.ok) {
          throw new Error('Erro ao buscar trabalhos');
        }

        const data = await response.json();
        setJobs(data);
      } catch (error) {
        console.error('Erro ao buscar trabalhos:', error);
      }
    } else {
      console.error('Usuário não autenticado ou email não disponível');
    }
  };
  
  const handleOpenModal = (job: Job) => {
    setSelectedJob(job);
    setSelectedJobTxId(job.txId); 
    setPaymentConfirmed(false);
    setPixCode(job.pixCopiaECola);
    setQrCodeUrl(job.pixCopiaECola);
    setShowModal(true);
  };

  const handleCloseModal = () => {
    setShowModal(false);
    setSelectedJob(null);
    setQrCodeUrl(null);
    setPaymentConfirmed(false);
  };

  const handleCopyPix = () => {
    if (pixInputRef.current) {
      pixInputRef.current.select();
      document.execCommand('copy');
      alert('Código PIX copiado para a área de transferência!');
    }
  };

  const deleteJob = async (job: Job) => {
    try {
      const response = await fetch(`${process.env.REACT_APP_JOB_SERVICE_URL}/job/${job.id}`, { 
        method: 'DELETE',
        headers: {
          'Authorization': `Bearer ${token}`,
          'Content-Type': 'application/json',
        },
      });
  
      if (!response.ok) {
        toast.error('Erro ao deletar o serviço');
        throw new Error('Failed to delete job');
      } else {
        toast.success('Serviço deletado com sucesso');
        fetchJobs();
      }
    } catch (error) {
      console.error('Error deleting job:', error);
    }
  };
  
  return (
    <div className="container-request-jobs-page">
      <ToastContainer />
      <Header />
      {jobs.length > 0 ? (
        <div className="add-items">
          <div className="add-items-header">
            <h3 className='pt-5'>Pedidos Feitos</h3>
          </div>
          {jobs.map((job, index) => (
            <div key={index} className="item-adicionado">
              <div className="pedido-card">
                <div className="d-flex justify-content-between align-items-start">
                  <h5 className="mb-0">{job.name}</h5>
                  <span className="status-badge status-pendente">Novo</span>
                </div>
                <div className="pedido-details">
                  <div className="pedido-info">
                    <h2>{job.title}</h2>
                    <p><strong>Peso:</strong> {job.weight}kg</p>
                    <p><strong>Descrição:</strong> {job.description}</p>
                    <p><strong>Valor:</strong> R$ {job.valor}</p>
                    <p><strong>Endereço:</strong> {job.endereco}</p>
                  </div>
                  <div className="pedido-info">
                    {job.imagem && (
                      <p>
                        <strong>Imagem:</strong>
                        <img src={URL.createObjectURL(job.imagem)} alt="Item" className="item-image" />
                      </p>
                    )}
                  </div>
                </div>  
                {job.pagamento == 'feito'?(
                  <div className="payment-completed">
                <div className="pedido-actions justify-content-center">
                <div className="d-flex align-items-center">
                <button className="btn rounded border bg-button-success">
                    <FontAwesomeIcon icon={faCheck} />
                    <span className='m-3'>Pago</span>
                  </button>
                </div>
                  {/* <button className="btn rounded border border-black" onClick={() => deleteJob(job)}>
                    <FontAwesomeIcon icon={faTrash} />
                    <span className='m-3'>Excluir</span>
                  </button> */}
                </div>
                </div>
                ):( 
                <div className="pedido-actions">
                    <div className="d-flex align-items-center rounded border border-black">
                      <button className="btn" onClick={() => handleOpenModal(job)}>
                        <FontAwesomeIcon icon={faCartArrowDown} /> 
                        <span className='m-3'>Pagar</span>
                      </button>
                    </div>
                  <button className="btn" onClick={() => deleteJob(job)}>
                  </button>
                  <div className="d-flex align-items-center rounded border border-black">
                      <button className="btn" onClick={() => deleteJob(job)}>
                        <FontAwesomeIcon icon={faTrash} />
                        <span className='m-3'>Excluir</span>
                      </button>
                    </div>
                </div>
                  )}
              </div>
            </div>
          ))}
        </div>
      ) : (
        <p>Nenhum pedido encontrado.</p>
      )}
      <Footer />
      <Modal show={showModal} onHide={handleCloseModal}>
        <Modal.Header closeButton>
          <Modal.Title>Pagamento via PIX</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <div className="text-center">
            {paymentConfirmed ?  (
              <div className="confirmation-section mt-4">
                <h2>
                  <FontAwesomeIcon icon={faCheck} style={{ color: 'green', fontSize: '50px' }} />
                </h2>
                <p>Pagamento Confirmado!</p>
              </div>
            ) : (
              <>
                {qrCodeUrl && (
                  <div className="qr-code-section mt-4">
                    <p>Escaneie o código QR:</p>
                    <QRCodeCanvas value={qrCodeUrl} size={200} />
                  </div>
                )}
                {pixCode && (
                  <div className="pix-section">
                    <p>Código PIX:</p>
                    <input 
                      type="text" 
                      value={pixCode} 
                      readOnly 
                      ref={pixInputRef} 
                      className="form-control" 
                    />
                    <div className="align-center">
                      <button onClick={handleCopyPix} className="btn mt-2">
                        <FontAwesomeIcon icon={faCopy} /> Copiar PIX
                      </button>
                    </div>
                  </div>
                )}
              </>
            )}
          </div>
        </Modal.Body>
      </Modal>
    </div>
  );
};

export default RequestJobsPage;
