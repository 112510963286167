import React, { useEffect, useState } from 'react';
import { BrowserRouter as Router, Routes, Route, Link, useNavigate } from "react-router-dom";
import { toast, ToastContainer } from 'react-toastify';
import { useAuth } from '../contexts/AuthProvider';
import api_user_service from '../api/api';
import User from '../types/UserLogin';
import '../styles/Login.css';

const Login: React.FC = () => {
  const [user, setUser] = useState<User>({
    email: '',
    password: '',
  });
  const [isLoading, setIsLoading] = useState(false);
  const { login, isAuthenticated } = useAuth();
  const navigate = useNavigate();
  
  useEffect(() => {
    if (isAuthenticated) {
      navigate('/home');
    }
  }, [isAuthenticated, navigate]);

  const handleChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const { name, value } = e.target;
    setUser(prevUser => ({
      ...prevUser,
      [name]: value,
    }));
  };

  const handleSubmit = async (e: React.FormEvent<HTMLFormElement>) => {
    e.preventDefault();
    setIsLoading(true);
    
    try {
      const response = await api_user_service.post("/login", user, {
        headers: {
          "Content-Type": "application/json",
        },
        withCredentials: true,
      });
  
      const data = response.data;
  
      if (data.access_token) {
        await login(data.access_token);
        navigate('/home');
      }
    } catch (error) {
      console.error('Erro ao fazer login:', error);
      toast.error('Erro ao fazer login. Verifique suas credenciais e tente novamente.');
    } finally {
      setIsLoading(false);
    }
  };

  return (
    <div className="container d-flex justify-content-center align-items-center min-vh-100">
      <div className="card shadow-lg p-4" style={{ width: "400px", borderRadius: "15px" }}>
        <h3 className="text-center mb-4">Login</h3>
        <form onSubmit={handleSubmit}>
          <div className="mb-3">
            <label className="form-label">Email</label>
            <div className="input-group">
              <input
                type="email"
                className="form-control"
                placeholder="Digite seu email"
                name="email"
                value={user.email}
                onChange={handleChange}
                required
              />
            </div>
          </div>

          <div className="mb-3">
            <label className="form-label">Senha</label>
            <div className="input-group">
              <input
                type="password"
                className="form-control"
                placeholder="Digite sua senha"
                name="password"
                value={user.password}
                onChange={handleChange}
                required
              />
            </div>
          </div>

          <div className="mb-3">
            <div className="form-check">
              <input
                type="checkbox"
                className="form-check-input"
                id="customCheck1"
              />
              <label className="form-check-label" htmlFor="customCheck1">
                Lembrar-me
              </label>
            </div>
          </div>

          <div className="d-grid">
            <button
              type="submit"
              // onClick={handleLogin}
              className="button-color"
              disabled={isLoading}
            >
              {isLoading ? "Carregando..." : "Entrar"}
            </button>
          </div>

          <p className="forgot-password text-center mt-3">
            Já possui uma conta? <Link to="/cadastro" className="text-primary link">Cadastre-se aqui</Link>
          </p>
        </form>
      </div>
      <ToastContainer />
    </div>
  );
};

export default Login;