import "./App.css";
import { BrowserRouter as Router, Routes, Route } from "react-router-dom";
import { AuthProvider } from "./contexts/AuthProvider";
import Servicos from "./pages/Servicos";
import 'bootstrap/dist/css/bootstrap.min.css';
import Cadastro from "./pages/Cadastro";
import Login from "./pages/Login";
import Home from "./pages/Home";
import RequestJobsPage from "./pages/RequestJobsPage";
import ProfilePage from "./pages/ProfilePage";
import SearchPage from "./pages/SearchPage";

function App() {
  return (
    <div className="App">
      <Router>
        <AuthProvider>
          <Routes>
            <Route path="/" Component={Login} />
            <Route path="/home" Component={Home} />
            <Route path="/servicos" Component={Servicos} />
            <Route path="/request-jobs-page" Component={RequestJobsPage} />
            <Route path="/profile" Component={ProfilePage} />
            <Route path="/search" Component={SearchPage} />
            <Route path="/cadastro" Component={Cadastro} />
          </Routes>
        </AuthProvider>
      </Router>
    </div>
  );
}

export default App;
