import axios from 'axios';

const api_user_service = axios.create({
  baseURL: process.env.REACT_APP_USER_SERVICE_URL,
  withCredentials: true,
});

api_user_service.defaults.headers.common['Content-Type'] = 'application/json';

export default api_user_service;
