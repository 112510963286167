import React from 'react';
import { useLocation, Link } from 'react-router-dom';
import '../styles/Footer.css';

const Footer: React.FC = () => {
  const location = useLocation();

  return (
    <footer className="footer">
      <nav className="footer-nav">
        <Link
          to="/home"
          className={`footer-item ${location.pathname === '/home' ? 'active' : ''}`}
        >
          <i className="fas fa-home"></i>
          <span>Início</span>
        </Link>
        <Link
          to="/search"
          className={`footer-item ${location.pathname === '/search' ? 'active' : ''}`}
        >
          <i className="fas fa-search"></i>
          <span>Buscar</span>
        </Link>
        <Link
          to="/request-jobs-page"
          className={`footer-item ${location.pathname === '/request-jobs-page' ? 'active' : ''}`}
        >
          <i className="fas fa-list-alt"></i>
          <span>Pedidos</span>
        </Link>
        <Link
          to="/profile"
          className={`footer-item ${location.pathname === '/profile' ? 'active' : ''}`}
        >
          <i className="fas fa-user"></i>
          <span>Perfil</span>
        </Link>
      </nav>
    </footer>
  );
};

export default Footer;
