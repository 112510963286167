import { faCouch, faBolt, faMagnet, faArchive, faPlug, faBox } from '@fortawesome/free-solid-svg-icons';

const Itens = [
    { name: 'Linha Branca', value: 80, icon: faPlug},
    { name: 'Eletrodomésticos', value: 40, icon: faBolt},
    { name: 'Estofados', value: 100, icon: faCouch},
    { name: 'Ferro Velho', value: 50, icon: faMagnet},
    { name: 'Armários', value: 120, icon: faArchive},
    { name: 'Outros', value: 30, icon: faBox},
  ];

export default Itens;