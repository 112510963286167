import React from 'react';
import { useNavigate } from 'react-router-dom';
import '../styles/Header.css';

const Header: React.FC = () => {
  const navigate = useNavigate();

  return (
    <header className="header">
      <div className="back-arrow" onClick={() => navigate(-1)}>
        <i className="fas fa-arrow-left"></i>
      </div>
      <div className="search-container">
        <i className="fas fa-search search-icon"></i>
        <input
          type="text"
          className="search-input"
          placeholder="Buscar item para descarte..."
        />
      </div>
    </header>
  );
};

export default Header;
